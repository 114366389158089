// @ts-check
/** @enum {string} */
export const CONTENT_ID_ENUM = {
  HOME: 'Homepage.FragranceFinder.Start',
  SEARCH: 'Homepage.FragranceFinder.Search',
  CONCEPTS: 'Homepage.FragranceFinder.Descriptors',
  GENDER: 'Homepage.FragranceFinder.Gender',
  RECOMMENDATIONS: 'Homepage.FragranceFinder.Recommendations',
  PDP: 'Homepage.FragranceFinder.PDP',
}
/** @enum {string} */
export const TYPES_ENUM = {
  RECO: 'FragranceFinderRecommendation',
  PERFUME_VIEW: 'FragranceFinderArticleDetailPage',
}
/** @enum {string} */
export const CATEGORY = {
  FOR_ME: 'Fragrance for me',
  FOR_GITF: 'Fragrance for a gift',
}
/** #59 changed for string. Feedback @enum {string} */
export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
  UNISEX: 'Unisex',
}
/** @enum {string} */
export const CUSTOMER_STATUS = {
  REGISTER_CUSTOMER: 'RC',
  WALK_IN: 'WI', // if user chooses the Walk-in Option on the Checkout Login Page
  NEW_ACCOUNT: 'NC',
  NO_IDENTIFIED: 'XX',
}
/** @enum {string} */
export const CUSTOMER_LOGIN_STATUS = {
  SOFT_LOGGED_IN: 'Soft Logged-In', //User was logged-in in the previous session and did not log out
  LOGGED_IN: 'Logged-In', // User has actively logged-in
  LOGGED_OUT: 'Logged-Out', //User does not have any of the above statuses
}
/**
 * @param {CONTENT_ID_ENUM} content_id
 * @param {CATEGORY} category02
 * @param {GENDER} gender
 * @param {Object} customer
 * @param {CUSTOMER_STATUS=} customer.customer_status Identifies the status of a customer on every page. Status must be written in CAPS. If not specified otherwise, status changes are measured with the next page request. Status remains the same after a change, until the user acively switches into another status
 * @param {(string | number)=} customer.customer_id Hybris Customer ID (if information available/user logged in)
 * @param {CUSTOMER_LOGIN_STATUS=} customer.customer_login_status Captures the current login status on every page: Soft Logged-In = User was logged-in in the previous session and did not log out (is greeted with name in the header), Logged-In = User has actively logged-in, Logged-Out = User does not have any of the above statuses
 * @param {string=} customer.cdc_id CDC Customer ID (if information available/user logged in)
 * @param {string=} customer.customer_birth_day Date of birth [YYYYMMDD]. Date of birth in YYYYMMDD format if information is available (user-added information to his profile). since the GDPR we need here only the Year. Please add "01" for Day and Month: 01011976
 * @param {GENDER=} customer.customer_gender Gender, if available (user-added information to his profile):
 * @param {Object} error
 * @param {string=} error.object_code Captures any Error Code delivered to th/backend. Format = Error Object : Error Code. Captures any Error Code delivered to the frontend/backend. Format = Error Object : Error Code. If multiple errors need to be passed, please separate them by a semicolon. (i.e. "DouglasCard:25;Play:recommendations"); <br/> If an error appears with the page load, the parameter is passed directly with the page request. If an error appears later (either through user interaction or because of a timed delay), the parameter should be populated together with all previously populated parameters (page, user and product data) and utag.view() should be retriggered.
 * @param {string=} error.message Displayed text of error messages or validation/error information if no text is displayed to the user. List of strings (delimiter: semicolon ";"), e.g. "Error1";"Error2".
 * @param {string} basket_ID Completed Baskets. Hybris BasketID. Hybris basket ID should be passed only once per completed basket. It is passed when the user indicates that he wants to checkout.
 * @param {Object} primary_product
 * @param {string=} primary_product.id Product ID. Captured on every product interaction (recommendation view or PDP view). If multiple values need to be passed, please separate them by a semicolon.
 * @param {string=} primary_product.master_id Master ID. ID of the master product. This is only written into the database once per ProductID. If the product moves, this is not updated. If this needs to be updated because the page has moved, please contact the Digital Analytics Team.
 * @param {string=} primary_product.additional_information The Product Perfume Family (in English) - one value per product. If possible, all values should be passed in English. If multiple values need to be passed, please separate them by a semicolon.
 * @param {string=} primary_product.type If multiple values need to be passed, please separate them by a semicolon.
 * @param {string=} primary_product.view_type The Product View Type. Describes the place/way the product view occurred. Examples: Product Quick View, Article Detail Page, Basket
 * @param {string=} primary_product.master_name Master Name. Name of the Master Product. This is only written into the database once per ProductID. If the product moves, this is not updated. If this needs to be updated because the page has moved, please contact the Digital Analytics Team.
 * @param {string=} primary_product.name Names of the Products.\n If multiple values need to be passed, please separate them by a semicolon.
 * @param {string=} primary_product.variant_name Product Name. Name of the variant of a master product. This is only written into the database once per ProductID. If the product moves, this is not updated. If this needs to be updated because the page has moved, please contact the Digital Analytics Team.
 * @param {string=} primary_product.brand Brand of the product. This is only written into the database once per ProductID. If the product moves, this is not updated. If this needs to be updated because the page has moved, please contact the Digital Analytics Team.
 * @param {string=} primary_product.classification Product classification of the master product. Classification Level 1. Maintained by purchasing team. This is only written into the database once per ProductID. If the product moves, this is not updated. If this needs to be updated because the page has moved, please contact the Digital Analytics Team. If multiple values need to be passed, please separate them by a semicolon.
 * @param {string=} primary_product.maincategory Category Group. Category Name & Main Category ID. Format: category name (main category group id). Category Group of Purchasing Departement. Category Name & Main Category ID as in Hybris & SAP. Do not mix up with the classification level (ca6). This is only written into the database once per ProductID. If the product moves, this is not updated. If this needs to be updated because the page has moved, please contact the Digital Analytics Team.
 * @param {Object} recommended
 * @param {string=} recommended.perfume_family All perfume families recommended to the user on the Fragrance Finder Recommendation Page. If possible, all values should be passed in English. If multiple values need to be passed, please separate them by a semicolon.
 * @param {string=} recommended.perfume_ingredients All perfume ingredients recommended to the user on the Fragrance Finder Recommendation Page. If possible, all values should be passed in English. If multiple values need to be passed, please separate them by a semicolon.
 * @param {string} language_code
 * @param {string} country_code
 * @returns
 */
export async function trackRouter(
  content_id,
  customer = { customer_status: CUSTOMER_STATUS.NO_IDENTIFIED, customer_login_status:CUSTOMER_LOGIN_STATUS.SOFT_LOGGED_IN },
  category02,
  gender,
  primary_product = {},
  recommended = {},
  error = {},
  layer_name,
  basket_ID,
  language_code = 'DE',
  country_code = 'DE'
) {
  /** utag_data in in index.html script. It is necesary for enabling to see data to Douglas team */
  // @ts-ignore
  // eslint-disable-next-line no-undef
  utag_data = {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    page_url: encodeURIComponent(window.location.toString()),
    sitegroup: 'FragranceFinderPage',
    content_id,
    page_id: content_id,
    shop_type: 'shop',
    language_code,
    country_code,
    category01: 'Fragrance Finder',
    category02,
    category03: gender,
    layer_name, //Captures the names of layers shown to the users,
    customer_status: customer.customer_status,
    customer_login_status: customer.customer_login_status,
    customer_id: customer.customer_id,
    customer_birth_day: customer.customer_birth_day,
    customer_gender: customer.customer_gender,
    cdc_id: customer.cdc_id,
    error_object_code: error.object_code,
    error_message: error.message,
    basket_ID,
    primary_product_id: primary_product.id,
    primary_product_master_id: primary_product.master_id,
    primary_product_additional_information: primary_product.additional_information,
    primary_product_type: primary_product.type,
    primary_product_view_type: primary_product.view_type,
    primary_product_master_name: primary_product.master_name,
    primary_product_name: primary_product.name,
    primary_product_variant_name: primary_product.variant_name,
    primary_product_brand: primary_product.brand,
    primary_product_classification: primary_product.classification,
    primary_product_maincategory: primary_product.maincategory,
    recommended_perfume_family: recommended.perfume_family,
    recommended_perfume_ingredients: recommended.perfume_ingredients,
  }
  // @ts-ignore
  // eslint-disable-next-line no-undef
  return utag.view(utag_data)
}
