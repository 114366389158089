import { mapGetters } from 'vuex'
import {
  CATEGORY,
  CONTENT_ID_ENUM,
  CUSTOMER_LOGIN_STATUS,
  CUSTOMER_STATUS,
  GENDER,
  trackRouter,
  TYPES_ENUM,
} from '../services/douglasTrack'
import { ROUTER_NAMES } from '../settings'
import {
  getFamiliesWithSubFam,
  getInfoFromEanData,
  getProfileMainIngredients,
} from '../utils/perfumeFunctions'
import QueryStringMixin from './QueryStringMixin'
/**
 * Get information from first element in EAN DATA witch has the field filled
 * @param {T[]} perfumes Perfumes
 * @param {keyof T} field field of perfume
 * @template T
 * @returns {string}
 */
function getFieldsFromEanPerfumes(perfumes, field) {
  if (perfumes.length === 1) {
    let obj = getInfoFromEanData(perfumes[0], field)
    return obj[field] ?? null
  }
  return perfumes
    .map(perfume => {
      let obj = getInfoFromEanData(perfume, field)
      return obj ? obj[field] ?? null : null
    })
    .join(';')
}

/**
 * @typedef CustomerInformation
 * @property {CUSTOMER_STATUS=} customer_status Identifies the status of a customer on every page. Status must be written in CAPS. If not specified otherwise, status changes are measured with the next page request. Status remains the same after a change, until the user acively switches into another status
 * @property {(string | number)=} customer_id Hybris Customer ID (if information available/user logged in)
 * @property {CUSTOMER_LOGIN_STATUS=} customer_login_status Captures the current login status on every page: Soft Logged-In = User was logged-in in the previous session and did not log out (is greeted with name in the header), Logged-In = User has actively logged-in, Logged-Out = User does not have any of the above statuses
 * @property {string=} cdc_id CDC Customer ID (if information available/user logged in)
 * @property {string=} customer_birth_day Date of birth [YYYYMMDD]. Date of birth in YYYYMMDD format if information is available (user-added information to his profile). since the GDPR we need here only the Year. Please add "01" for Day and Month: 01011976
 * @property {GENDER=} customer_gender Gender, if available (user-added information to his profile):
 */
/**
 * we must to keep sintcroniced the fetchs
 */
export default {
  props: {
    id: String, // Perfume id
  },
  mixins: [QueryStringMixin],
  computed: {
    ...mapGetters({ $user: 'profiler/user',isUserCentricLoaded:'isUserCentricLoaded' }),
  },
  methods: {
    /**
     *
     * @param {Perfume[]} perfumes
     * @param {Perfume[]} profilerPerfumes
     * @param {Object} error
     * @param {string} error.message
     * @param {string} error.object_code
     */
    async douglasTrack(perfumes = [], profilerPerfumes = [], layerName, error) {
      const ucscript = document.getElementById('usercentrics-cmp')
      console.log('[DT] user centric',ucscript.loaded ? 'loaded' : 'not loaded')
      // If user centric is not loaded, we can't send track
      if(!ucscript.loaded){
        console.log('[DT] user centric', 'waiting...')
        // add listener in order to track the current track
        const listener = window.addEventListener('UTAG_INITIALIZED', () => {
          // add async function for updating the dom data
          console.log('[DT] tealium finish load')
          setTimeout(()=>{
            this.douglasTrack(perfumes,profilerPerfumes,layerName,error)
            window.removeEventListener('UTAG_INITIALIZED',listener)  
          },100)
        })
        return 
      } 
      const gender = this.qsGender
      const categoryParam = this.qsForMe === true ? CATEGORY.FOR_ME : CATEGORY.FOR_GITF

      const genderParam =
        gender === 'M'
          ? GENDER.MALE
          : gender === 'F'
          ? GENDER.FEMALE
          : gender === 'U'
          ? GENDER.UNISEX
          : null
      /** @type {CustomerInformation} */
      const customerData = {
        cdc_id: this.$user ?? null,
        customer_birth_day: null,
        customer_gender: null,
        customer_id: this.$user ?? null,
        customer_login_status: this.$user
          ? CUSTOMER_LOGIN_STATUS.LOGGED_IN
          : CUSTOMER_LOGIN_STATUS.LOGGED_OUT,
        customer_status: this.$user
          ? CUSTOMER_STATUS.REGISTER_CUSTOMER
          : CUSTOMER_STATUS.NO_IDENTIFIED,
      }
      switch (this.$route.name) {
        case ROUTER_NAMES.WELCOME:
          trackRouter(
            CONTENT_ID_ENUM.HOME,
            customerData,
            null,
            null,
            {},
            {},
            error ?? {},
            layerName
          )
          break
        case ROUTER_NAMES.SEARCH_FRAGRANCE:
          trackRouter(
            CONTENT_ID_ENUM.SEARCH,
            customerData,
            categoryParam,
            null,
            {},
            {},
            error ?? {},
            layerName
          )
          break
        case ROUTER_NAMES.CONCEPTS:
          trackRouter(
            CONTENT_ID_ENUM.CONCEPTS,
            customerData,
            categoryParam,
            null,
            {},
            {},
            error ?? {},
            layerName
          )
          break
        case ROUTER_NAMES.GENDER:
          trackRouter(
            CONTENT_ID_ENUM.GENDER,
            customerData,
            categoryParam,
            null,
            {},
            {},
            error ?? {},
            layerName
          )
          break
        case ROUTER_NAMES.NOT_FOUND:
          trackRouter(
            null,
            customerData,
            null,
            null,
            {},
            {},
            {
              object_code: 'PageNotFound: 404',
              message: 'Page not found',
            },
            layerName
          )
          break
        case ROUTER_NAMES.RECOMMENDATIONS:
        case ROUTER_NAMES.PERFUME:
          /** Get info from puig data */
          var brandNames = perfumes.map(perfume => perfume.brand.name).join(';')
          var families = perfumes.map(perfume => perfume.family.name).join(';')
          var familiesReco = getFamiliesWithSubFam(profilerPerfumes)
            .map(family => family.family.name)
            .join(';')
          var ingredientsReco = getProfileMainIngredients(profilerPerfumes).map(ingredient => ingredient.name).join(';')
          /** Get info from EAN DATA (douglas product feed)*/
          /*
          Sebastian option: Send 
          var perfumesNames = perfumes
          .map(perfume => {
            let obj = getInfoFromEanData(perfume, 'baseProductName')
            return obj ? obj.brandLineName + ' ' + obj.baseProductName ?? 'null' : 'null'
          })
          .join(';')
          */
          /** Jan Option */
          var perfumesNames = getFieldsFromEanPerfumes(perfumes, 'brandLineName')
          var masterNames = getFieldsFromEanPerfumes(perfumes, 'baseProductName')
          var perfumesIds = getFieldsFromEanPerfumes(perfumes, 'douglasPerfumeId')
          var perfumesVariant = getFieldsFromEanPerfumes(perfumes, 'variantName')
          var perfumesSkus = getFieldsFromEanPerfumes(perfumes, 'sku')
          var classification = getFieldsFromEanPerfumes(perfumes, 'classificationCategory')
          var categoryOneNameDe = getFieldsFromEanPerfumes(perfumes, 'productCategory')
          var productType = getFieldsFromEanPerfumes(perfumes, 'productType')
          trackRouter(
            this.$route.name === ROUTER_NAMES.RECOMMENDATIONS
              ? CONTENT_ID_ENUM.RECOMMENDATIONS
              : CONTENT_ID_ENUM.PDP,
            customerData,
            categoryParam,
            genderParam,
            {
              name: perfumesNames,
              brand: brandNames,
              id: perfumesSkus,
              additional_information: families,
              master_name: masterNames,
              master_id: perfumesIds,
              variant_name: perfumesVariant,
              classification,
              maincategory: categoryOneNameDe,
              type: productType,
              view_type: this.$route.name === ROUTER_NAMES.RECOMMENDATIONS
                    ? TYPES_ENUM.RECO
                    : TYPES_ENUM.PERFUME_VIEW,
            },
            {
              perfume_family: familiesReco,
              perfume_ingredients: ingredientsReco,
            },
            {},
            layerName
          )
          break
      }
    },
  },
}
