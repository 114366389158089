import { ROUTER_NAMES } from "../settings"

/**
 *
 * @param {string | string[]} field
 * @returns
 */
export default function createWatchErrorMixin(field) {
  const fields = Array.isArray(field) ? field : [field]
  return {
    watch: {
      ...fields.reduce(
        (obj, field) => ({
          ...obj,
          [field](value) {
            if ((value && !Array.isArray(value)) || (Array.isArray(value) && value.some(error => !!error))) {
              console.error(value)
              this.$router.push({name:ROUTER_NAMES.MAINTENANCE})
            }
          },
        }),
        {}
      ),
    },
  }
}
