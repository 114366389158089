<template>
  <div class="perfume-row">
    <basic-checkbox :value="selected" @change="handleClick" />
    <div class="perfume-image" :style="'background-image: url(' + perfume.imageUrl + ')'" />
    <div class="title-container">
      <div class="perfume-name">{{ perfume.brand.name }}</div>
      <div class="perfume-brand-container">
        <div :class="['perfume-brand']">{{ perfume.name }}</div>
      </div>
      <family-with-icon
        v-if="isMobile"
        class="family-with-icon"
        :family="perfume.family"
        :subfamily="perfume.secondaryFamily"
      />
    </div>
    <family-with-icon
      v-if="!isMobile"
      class="family-with-icon"
      :family="perfume.family"
      :subfamily="perfume.secondaryFamily"
    />
  </div>
</template>

<script>
import BasicCheckbox from '../basic/BasicCheckbox.vue'
import FamilyWithIcon from '../FamiliesWithIcon.vue'
export default {
  components: { BasicCheckbox, FamilyWithIcon },
  props: {
    perfume: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
  },
  methods: {
    handleClick(event) {
      this.$emit('click', this.perfume, event)
    },
  },
}
</script>

<style lang="stylus" scoped>
.perfume-row
  display: flex
  align-items: center
  padding: vw(16px) vw(26px)
  width: 100%

  &:not(:last-child)
    border-bottom: 1px solid #E6E6E6

  .mobile &
    display: flex
    flex-wrap: wrap
    padding: vw(15px)

.perfume-image
  margin-right: vw(20px)
  margin-left: vw(10px)
  width: vw(60px)
  height: vw(60px)
  background-position: center center
  background-size: contain
  background-repeat: no-repeat

  .mobile &
    width: vw(60px)
    height: vw(60px)

.title-container
  display: flex
  flex: 1 1 0%
  flex-direction: column
  align-self: stretch
  overflow: hidden
  margin-top: vw(12px)
  margin-right: vw(15px)

  .mobile &
    margin-top: 0
    margin-right: 0

.perfume-name
  m-font-size(12, 16)
  margin-bottom: vw(5px)
  text-transform: uppercase
  font-weight: 900

.perfume-brand-container
  display: flex
  align-items: center

.perfume-brand
  m-ellipsis(100%)
  m-font-size(12, 16)

.product-separator
  margin-right: vw(10px)
  margin-left: vw(10px)
  width: 1px
  height: 75%
  background-color: #000

.product-line
  m-ellipsis(100%)
  m-font-size(12, 16)
  margin-top: 0.1em

.perfume-another-thing
  m-font-size(12, 16)

.family-with-icon
  flex: 0.5 1 0%

  .mobile &
    flex: 1 1 0%
    margin-top: vw(5px)
    margin-left: vw(-4px)
    width: 100%
</style>