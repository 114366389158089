<template>
  <basic-loading v-if="loading" />
  <div v-else-if="perfumes.length>0" class="perfume-list">
    <perfume-row
      v-for="perfume in internalPerfumes"
      :key="perfume.id"
      :perfume="perfume"
      :selected="isPerfumeSelected(perfume.id)"
      @click="handleClick"
    />
  </div>
  <div v-else class="not-found">
    <div class="wrapper">{{ $t('search_not_found') }}</div>
  </div>
</template>

<script>
import BasicLoading from '../../components/basic/BasicLoading.vue'
import QueryStringMixin from '../../mixins/QueryStringMixin'
import PerfumeRow from './PerfumeRow.vue'
export default {
  components: {
    BasicLoading,
    PerfumeRow,
  },
  mixins: [QueryStringMixin],
  props: {
    perfumes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      savedPerfumes: this.perfumes,
    }
  },
  computed: {
    internalPerfumes() {
      if (this.loading) return this.savedPerfumes
      return this.perfumes
    },
  },
  watch: {
    loading: {
      handler(value) {
        if (value === false) {
          this.savedPerfumes = this.perfumes
        }
      },
      immediate: true,
    },
  },
  methods: {
    isPerfumeSelected(id) {
      return this.qsSelectedPerfumes.some(qsId => qsId.toString() === id.toString())
    },
    handleClick(perfume, isAdded) {
      const copy = [...this.qsSelectedPerfumes]
      if (isAdded) {
        copy.push(perfume.id)
        this.qsSelectedPerfumes = copy
      } else {
        const index = copy.indexOf(perfume.id.toString())
        if (index < 0) {
          console.error('index of id ', perfume.id, ' not found')
          return
        }
        copy.splice(index, 1)
        this.qsSelectedPerfumes = copy
      }
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.qsSearch = ''
      }, 1000)
    },
  },
}
</script>

<style lang="stylus" scoped>
.perfume-list
  overflow-x: hidden
  overflow-y: auto
  width: 100%
  height: 100%
  background-color: #fff
.not-found
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  m-font-size(20,35)
  color #aaa
  .wrapper
    max-width: vw(600px)
    text-align: center
  
  .mobile &
    margin-top vh(40px)
    m-font-size(20,30)
</style>