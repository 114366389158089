<template>
  <div class="concept-pair">
    <concept-box :selected="isSelected(concept1.id)" :concept="concept1" @click="handleClick(concept1)" />
    <div
      ref="tougleContainer"
      :class="['toogle-container', {'not-selected': !isSelected(concept1.id) && !isSelected(concept2.id)}]"
      @click="handleClickTougle"
    >
      <div 
        ref="toggle" 
        :class="['toggle', {'left': isSelected(concept1.id), 'right': isSelected(concept2.id)}]"
        :style="clicked ? {transform,transition:'none'} : {}"
      />
    </div>
    <concept-box :selected="isSelected(concept2.id)" :concept="concept2" @click="handleClick(concept2)" />
  </div>
</template>

<script>
import ConceptBox from './ConceptBox.vue'
export default {
  components: { ConceptBox },
  props: {
    conceptPair: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slideX: 0,
      clicked: false,
      proportion: 0,
    }
  },
  computed: {
    concept1() {
      return this.conceptPair[0]
    },
    concept2() {
      return this.conceptPair[1]
    },
    someSelected() {
      return this.isSelected(this.concept1.id) || this.isSelected(this.concept2.id)
    },
    transform() {
      const max = 50
      const min = -150

      var position = max * this.proportion + min * (1 - this.proportion)
      if (position > max) position = max
      if (position < min) position = min
      return `translate(${position}%, -50%)`
    },
  },
  mounted() {
    this.$refs.toggle.addEventListener('touchstart', this.HandleMouseDown, false)
    this.$refs.tougleContainer.addEventListener('touchmove', this.handleMouseMove, false)
  },
  methods: {
    /**
     * @param {TouchEvent} event
     */
    HandleMouseDown(event) {
      this.clicked = true
      this.proportion = this.calculateProportion(event)
      document.addEventListener('touchend', e => this.handleMouseUp(e, event), { once: true })
    },
    /**
     * @param {TouchEvent} eventUp
     * @param {TouchEvent} eventDown
     */
    handleMouseUp(eventUp, eventDown) {
      this.clicked = false
      var touchUp = eventUp.touches[0] || eventUp.changedTouches[0]
      var touchDown = eventDown.touches[0] || eventDown.changedTouches[0]
      //console.log(touchDown.clientX, touchUp.clientX)
      if (this.proportion < 0.6 && this.proportion > 0.4) return this.handleDelelect()
      if (touchDown.clientX < touchUp.clientX) this.handleClick(this.concept2)
      else this.handleClick(this.concept1)
    },
    /**
     * @param {TouchEvent} event
     */
    handleMouseMove(event) {
      //console.log('move', event.touches[0].clientX)

      this.proportion = this.calculateProportion(event)
    },
    /**
     * @param {TouchEvent} event
     */
    calculateProportion(event) {
      var touch = event.touches[0] || event.changedTouches[0]

      const clientX = touch.clientX
      const bounds = this.$refs.tougleContainer.getBoundingClientRect()
      const posX = clientX - bounds.left
      const proportion = (posX / this.$refs.tougleContainer.clientWidth) * 100
      if (proportion < 0) return 0
      if (proportion > 100) return 1
      return Math.trunc(proportion) / 100
    },
    isSelected(id) {
      return this.selected.indexOf(id.toString()) >= 0
    },
    handleClick(concept) {
      const selectedIndex = this.conceptPair.findIndex(c => c.id === concept.id)
      const otherConcept = this.conceptPair[(selectedIndex + 1) % 2]
      const id = concept.id
      const QSindex = this.selected.indexOf(id.toString())
      const otherConceptQSIndex = this.selected.indexOf(otherConcept.id.toString())
      const copy = [...this.selected]
      // if other concept is on query String
      if (otherConceptQSIndex >= 0) {
        copy.splice(otherConceptQSIndex, 1)
        copy.push(id)
        this.$emit('change', copy)
      }
      // if clicked concept isnt on query string
      else if (QSindex < 0) {
        copy.push(id)
        this.$emit('change', copy)
      }
      // if clicked concept is on queryString
      else {
        copy.splice(QSindex, 1)
        this.$emit('change', copy)
      }
    },
    handleClickTougle(event) {
      const offsetX = event.offsetX
      const width = event.target.clientWidth
      const middle = width / 2
      const third = width / 3
      if (this.someSelected) {
        //selected fisrth
        if (offsetX < third) this.handleClick(this.concept1)
        //unselected
        else if (offsetX < third * 2) {
          // selected 1 then "unselected" 1
          if (this.isSelected(this.concept1.id)) this.handleClick(this.concept1)
          // selected 2 then "unselected" 2
          else this.handleClick(this.concept2)
        }
        //selected last
        else this.handleClick(this.concept2)
      } else {
        if (offsetX < middle) this.handleClick(this.concept1)
        else this.handleClick(this.concept2)
      }
    },
    handleDelelect() {
      if (this.someSelected) {
        // selected 1 then "unselected" 1
        if (this.isSelected(this.concept1.id)) this.handleClick(this.concept1)
        // selected 2 then "unselected" 2
        else this.handleClick(this.concept2)
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
$toogle-width = 80px
$toggle-border = 4px
$toggle-height = 30px

.concept-pair
  display: flex
  align-items: center
  height: vw(80px)
  border-bottom: 1px solid #E6E6E6

  .mobile &
    height: vw(60px)

.toogle-container
  position: relative
  margin-right: vw(40px)
  margin-left: vw(40px)
  width: $toogle-width
  height: $toggle-height
  border: 1px solid $primary-theme-color
  border-radius: ($toggle-height / 2)
  background-color: $primary-theme-color
  cursor: pointer
  transition: all 0.25s ease-in-out

  .mobile &
    margin-right: vw(25px)
    margin-left: vw(25px)

  &.not-selected
    border-color: #bbb
    background-color: #fafafa

.toggle
  position: absolute
  top: 50%
  left: 50%
  width: $toggle-height - ($toggle-border * 2)
  height: $toggle-height - ($toggle-border * 2)
  border: 1px solid #bbb
  border-radius: 50%
  background-color: #fff
  transition: all 0.25s ease-in-out
  transform: translate(-50%, -50%)

  &.left
    border-color: $primary-theme-color
    transform: translate(-150%, -50%)

  &.right
    border-color: $primary-theme-color
    transform: translate(50%, -50%)
</style>