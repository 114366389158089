<template>
  <div class="concept-list">
    <div class="concept-title">{{ $t("concept_info") }}</div>
    <div class="concept-container">
      <pair-concepts
        v-for="(conceptPair,index) in groupedConcepts"
        :key="index"
        :concept-pair="conceptPair"
        :selected="qsConcepts"
        @change="handleChangeSelected"
      />
    </div>
  </div>
</template>

<script>
import QueryStringMixin from '../../mixins/QueryStringMixin'
import { CONCEPT_SETTING } from '../../settings'
import PairConcepts from './PairConcepts.vue'
export default {
  components: { PairConcepts },
  mixins: [QueryStringMixin],

  computed: {
    mapedConcepts() {
      return CONCEPT_SETTING.concepts.map(c => ({ ...c, name: this.$t(c.name) }))
    },
    groupedConcepts() {
      return this.mapedConcepts.reduce((result, c, i) => {
        if (i % 2 === 0) result.push([this.mapedConcepts[i], this.mapedConcepts[i + 1]])
        return result
      }, [])
    },
  },
  watch: {
    qsConcepts(newConcepts, oldConcepts) {
      const isNumber = str => Number.isInteger(Number(str))
      const formatConcepts = concepts => concepts.filter(c => isNumber(c)).map(c => Number(c))
      const cleanNewConcepts = formatConcepts(newConcepts)
      const cleanOldConcepts = formatConcepts(oldConcepts)
      const resultingProfile = { perfumes: this.qsSelectedPerfumes.map(p => parseInt(p)), forWhom: this.qsForMe ? 'ME' : 'THEM' } 
      const added = cleanNewConcepts.filter(c => !cleanOldConcepts.includes(c))
      const removed = cleanOldConcepts.filter(c => !cleanNewConcepts.includes(c))
      removed.map(id => this.eventTracker.emitEvent('REMOVE_FROM_PROFILE', {
        id,
        type: 'CONCEPT',
        resultingProfile: {
          ...resultingProfile,
          concepts: cleanOldConcepts.filter(c => c != id )
        }
      }))
      added.map(id => {
        isNumber(id) &&
        this.eventTracker.emitEvent('ADD_TO_PROFILE', {
          id: parseInt(id),
          type: 'CONCEPT',
          resultingProfile: {
            ...resultingProfile,
            concepts: cleanNewConcepts
          }
        })
      })
    }
  },
  mounted() {
    this.eventTracker.emitEvent('VIEW_OPENED', { type: 'SET_CONCEPTS' })
  },
  methods: {
    handleChangeSelected(newValue) {
      this.qsConcepts = newValue
    },
  },
}
</script>

<style lang="stylus" scoped>
.concept-list
  position: absolute
  top: 50%
  left: 50%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  transform: translate(-50%, (- 50%))

  .mobile &
    position: relative
    top: 0
    left: 0
    flex: 1 1 0%
    height: 0
    transform: none

.concept-title
  m-font-size(16, 26)
  margin-bottom: vw(50px)
  font-weight: 600

  .mobile &
    m-font-size(19, 26)
    margin-top: vw(15px)
    margin-bottom: vw(30px)
    padding-right: vw(30px)
    padding-left: vw(30px)
    text-align: center

.concept-container
  width: vw(650px)

  .mobile &
    flex: 1 1 0%
    overflow-y: auto
    width: 100%
</style>