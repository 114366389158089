<template>
  <div class="search-fragrance" :class="{'trasparent':isSearchBig}">
    <div v-if="!isConcepts" class="search-input-container" :class="{'center':isSearchBig && !isMobile}">
      <div v-if="isSearchBig" class="search-title">{{ $t("search_view_title") }}</div>
      <div v-if="isMobile" class="info">
        <div class="info-icon" />
        <div class="info-text">{{ $t('search_view_info') }}</div>
      </div>
      <basic-search v-model="qsSearch" :pladceholder-text="$t('search_placeholder')" />
      <div v-if="isSearchBig || (isMobile && !qsSearch)" class="search-text">{{ qsForMe ? $t('search_view_tipp_for_me') : $t('search_view_tipp_for_gift') }}</div>
    </div>
    <div v-if="!isSearchBig " :class="['fragrances-container', {'has-margin': !isConcepts}]">
      <div class="children">
        <!-- <basic-search v-model="qsSearch" class="search-input" /> -->
        <div class="info">
          <div class="info-icon" />
          <div v-if="!isConcepts" class="info-text">{{ $t('search_view_info') }}</div>
          <div v-else class="info-text">{{ $t('search_fragrance_info') }}</div>
        </div>
        <transition name="fade">
          <concept-list v-if="isConcepts" />
          <perfume-list v-else-if="perfumes" :perfumes="perfumes" :loading="isPerfumesUpdating" />  
        </transition>
      </div>
      <div class="selected">
        <selected-fragrances v-if="!isMobile" />  
      </div>
    </div>
  </div>
</template>

<script>
import BasicSearch from '../components/basic/BasicSearch.vue'
import PerfumeList from '../components/PerfumeList'
import SelectedFragrances from '../components/SelectedFragrances'
import ConceptList from '../components/ConceptList'
import QueryStringMixin from '../mixins/QueryStringMixin'
import TrackingMixin from '../mixins/TrackingMixin'
import createWatchErrorMixin from '../mixins/createWatchErrorMixin'
export default {
  components: {
    BasicSearch,
    PerfumeList,
    ConceptList,
    SelectedFragrances,
  },
  mixins: [QueryStringMixin, TrackingMixin, createWatchErrorMixin(['perfumesError'])],
  props: {
    isConcepts: {
      type: Boolean,
    },
  },
  computed: {
    isSearchBig() {
      return (!this.qsSearch || this.qsSearch === '') && this.qsSelectedPerfumes.length === 0
    },
    placeholderSearch() {
      return this.qsForMe
        ? this.$t('search_view_placeholder_for_me')
        : this.$t('search_view_placeholder_for_gift')
    },
  },
  watch: {
    perfumes(newPerfumes, oldPerfumes) {
      if (!newPerfumes) return
      // si cambia un perfume es porque el search ha cambiado
      if (newPerfumes && newPerfumes.length > 0) {
        if(JSON.stringify(oldPerfumes.map(({id}) => id).sort()) !== JSON.stringify(newPerfumes.map(({id}) => id).sort())) {
          this.eventTracker.emitEvent('SEARCH_PERFUMES', { type: 'TEXT', data: { queryString: this.qsSearch }, topResults: this.perfumes.map(({ id }) => id).slice(0, 10) })
        }
        this.douglasTrack(newPerfumes)
      } 
    },
    isConcepts() {
      this.douglasTrack([])
    },
    qsSelectedPerfumes(newPerfumes, oldPerfumes) {
      const resultingProfile = { perfumes: newPerfumes.map(p => parseInt(p)), concepts: this.qsConcepts.map(c => parseInt(c)), forWhom: this.qsForMe ? 'ME' : 'THEM' } 
      const added = newPerfumes.filter(p => !oldPerfumes.includes(p))
      const removed = oldPerfumes.filter(p => !newPerfumes.includes(p))
      added.map(a => this.eventTracker.emitEvent('ADD_TO_PROFILE', {
        id: parseInt(a),
        type: 'PERFUME',
        resultingProfile 
      }))
      removed.map(r => this.eventTracker.emitEvent('REMOVE_FROM_PROFILE', {
        id: parseInt(r),
        type: 'PERFUME',
        resultingProfile 
      }))
    }
  },
  mounted() {
    // si entramos a la vista y ya hay search, entocnes el track se mandará cuando se reciban los perfumes
    if (!this.qsSearch) this.douglasTrack()
    if (this.isConcepts) this.douglasTrack([])
  },
  remoteComputed: {
    perfumes: {
      model: 'perfumes/search',
      params() {
        return this.qsSearch ? [this.qsSearch] : null
      },
    },
  },
}
</script>

<style lang="stylus" scoped>
$height-search = vh(30px)
$height-search-big = vh(40px)

.search-fragrance
  position: relative
  display: flex
  flex-direction: column
  overflow: hidden
  height: 100%

  // background-color: #fff
  // transition: background-color 0.5s ease-in-out
  .desktop &
    padding-top: $sub-header-height

  .mobile &
    flex: 1 1 0%

  &.trasparent
    height: 100%

    // background-color: rgba(0, 0, 0, 0)
    .search-input-container
      width: vw(600px)

      .mobile &
        width: 100%

  .info
    display: flex
    align-items: center
    margin-bottom: vh(20px)

    .mobile &
      align-items: flex-start
      margin-bottom: vw(15px)

    .info-icon
      m-icon("info-small", 24)
      margin-top: -0.1em
      margin-right: vw(5px)

      .mobile &
        margin-top: -0.35em

    .info-text
      m-font-size(13, 17)
      flex: 1 1 0%
      color: $dark-gray

.search-title
  m-font-size(30, 44)
  margin-bottom: vw(25px)
  text-align: center
  font-weight: 700
  font-family: "Avenir"

  .mobile &
    display: none

.basic-search
  &:not(:last-child)
    margin-bottom: vw(25px)

    .mobile &
      margin-bottom: vw(20px)

.search-text
  m-font-size(14, 22)

  .mobile &
    m-font-size(12, 22)

.search-input-container
  position: absolute
  //top: $sub-header-height
  left: 0
  overflow: hidden
  padding: vw(30px) 0 vw(15px) vw(50px)
  width: vw(800px)
  // transition: opacity 0.5s ease-in-out
  transform: translate(0, 0)
  
  .mobile &
    top: 0
    padding: vw(20px)
    padding-bottom: vw(1px)
    width: 100%
    position relative
    
  &.center
    top: 50%
    left: 50%
    padding: 0
    transform: translate(-50%, -50%)

    .basic-search
      height: vw(60px)

  .basic-search-input
    transition: all 0.5s ease-in-out

.fragrances-container
  position: relative
  display: flex
  flex: 1 1 0%
  overflow: hidden

  &.has-margin
    margin-top: vw(88px)

    .mobile &
      margin-top: vw(0px)

      .children
        padding-top: 0
        padding-bottom: 0

    .children
      padding-top: 0

      .info
        .mobile &
          display: none

  .children
    position: relative
    display: flex
    flex: 0 0 70%
    flex-direction: column
    padding-top: vw(20px)
    padding-left: vw(50px)
    width: 60%

    .mobile &
      flex: 1 1 0%
      padding: vw(15px)
      width: 100%

    .fade-enter-active,
    .fade-leave-active
      transition: opacity 0.2s

    .fade-enter,
    .fade-leave-to
      opacity: 0

  .selected
    position: fixed
    top: vh(70px + 64px) // header-height + sub-header-height
    right: 0
    flex: 0 0 30%
    overflow: hidden
    margin-left: vw(30px)
    width: 30%
    height: 100%
    border-left: 1px solid #E6E6E6

    .mobile &
      display: none
</style>