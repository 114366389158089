/**
 * @typedef Family
 * @property {number} id
 * @property {string} name
 */
/**
 * @typedef Perfume
 * @property {string} name
 * @property {number} id
 * @property {string} imageUrl
 * @property {Family} family
 * @property {Family} secondaryFamily
 */
/**
 *
 * @param {Perfume[]} perfumes
 * @returns
 */
export function getFamiliesWithSubFam(perfumes) {
  if (!perfumes) return []
  let pairs = perfumes
    .filter(e => e)
    .map(p => {
      return { secondaryFamily: p.secondaryFamily, family: p.family, value: 1 }
    })
  let fams = Object.values(
    pairs.reduce((dict, { family, secondaryFamily }) => {
      dict[family.id] = dict[family.id] || { family: family, subFamilies: [] }
      dict[family.id].subFamilies.push(secondaryFamily)
      return dict
    }, {})
  )
  fams.forEach(f => {
    f.subFamilies = Object.values(
      f.subFamilies.reduce((dict, subFamily) => {
        dict[subFamily.id] = dict[subFamily.id] || { subFamily: subFamily, count: 0 }
        dict[subFamily.id].count += 1
        return dict
      }, {})
    )
    f.id = f.family.id
  })
  return fams
}
/**
 *
 * @param {Perfume[]} disliketPerfumes
 * @param {Perfume[]} likedPerfumes
 * @returns
 */
export function getUsesFamilies(likedPerfumes = [], disliketPerfumes = []) {
  let families = getFamiliesFromSignedPerfumes(likedPerfumes, disliketPerfumes) || []
  return families.splice(0, 6)
}
/**
 *
 * @param {Perfume[]} liked
 * @param {Perfume[]} disliked
 * @returns
 */
export function getFamiliesFromSignedPerfumes(liked, disliked) {
  let fams = liked
    .filter(p => p.family && p.secondaryFamily)
    .map(p => {
      return { secondaryFamily: p.secondaryFamily || p.family, family: p.family, inverted: false }
    })
  fams = fams.concat(
    disliked
      .filter(p => p.family && p.secondaryFamily)
      .map(p => {
        return { secondaryFamily: p.secondaryFamily || p.family, family: p.family, inverted: true }
      })
  )
  var counts = {}
  fams.forEach(x => {
    counts[x.family.id] = counts[x.family.id] || { val: 0, positiveNotes: [], negativeNotes: [] }
    // counts[x.family.id].val+=x.inverted?-1:1;
    if (x.inverted) counts[x.family.id].negativeNotes.push(x.secondaryFamily)
    else counts[x.family.id].positiveNotes.push(x.secondaryFamily)
  })

  fams = fams.filter((v, i, a) => a.findIndex(f => f.family.id === v.family.id) === i)
  fams = fams.map(f =>
    Object.assign({}, f, {
      positiveNotes: counts[f.family.id].positiveNotes,
      negativeNotes: counts[f.family.id].negativeNotes,
    })
  )
  fams.forEach(f => {
    delete f.secondaryFamily
    var subcounts = {}
    f.positiveNotes.forEach(x => {
      subcounts[x.id] = subcounts[x.id] || { val: 0, id: x.id }
      subcounts[x.id].val += 1
    })
    f.negativeNotes.forEach(x => {
      subcounts[x.id] = subcounts[x.id] || { val: 0, id: x.id }
      subcounts[x.id].val -= 1
    })

    let notes = f.positiveNotes
    notes = notes.filter((v, i, a) => a.findIndex(f => f.id === v.id) === i)
    notes = notes.map(n => Object.assign({}, n, { value: Math.max(0, subcounts[n.id].val) }))
    notes = notes.filter(n => n.value > 0)
    f.notes = notes
    f.value = f.notes.length
  })
  return fams.sort((a, b) => b.value - a.value)
}
/**
 *
 * @param {Perfume[]} perfumes
 * @returns {Ingredient[]}
 */
export function getProfileMainIngredients(perfumes) {
  let dict = perfumes.reduce((accum, p) => {
    p.ingredientProportions
      .sort((a, b) => b.proportion - a.proportion)
      .slice(0, 5)
      .forEach(ing => {
        let id = ing.ingredient.id
        if (!accum[id]) accum[id] = { value: 0, ing: ing.ingredient }
        accum[id].value += ing.proportion
      })
    return accum
  }, {})
  let list = Object.values(dict).sort((a, b) => b.value - a.value)
  return list.map(pp => pp.ing)
}
/**
 * 
 * @param {Perfume[]} perfumes selected perfumes by user
 * @param {{ingredient:Ingredient,proportion:number}[]} ingredientsProportions ingredients proportion of perfume
 * @returns 
 */
export function getCommonIngredients(perfumes,ingredientsProportions){
  let userIngredients = getProfileMainIngredients(perfumes)
  let commonIngs = ingredientsProportions
    .sort((a, b) => b.proportion - a.proportion)
    .slice(0, 5)
    .map(ip => ip.ingredient)
    .filter(ingredient =>
      userIngredients.find(phId => phId.id === ingredient.id 
        || phId.id === ingredient.synonymId 
        || (phId.synonymId && ingredient.synonymId && phId.synonymId === ingredient.synonymId )
        || phId.synonymId === ingredient.id)
    )
  return commonIngs
}

/**
 *
 * @param {{eanData:{}}} perfume
 * @param {string} requiredField
 * @return {undefined | Object}
 */
export function getInfoFromEanData(perfume, requiredField,debug=true) {
  if (!perfume?.eanData && debug) {
    console.warn(
      '[Track Warning]',
      'Perfume with id',
      perfume.id,
      perfume.name,
      'has a empty eanData',
      perfume
    )
    return null
  }
  const res = Object.values(perfume.eanData).find(eanData => eanData[requiredField])
  
  if (!res && debug){
    console.warn(
      '[Track Warning]',
      'Ean Data for perfume',
      perfume.id,
      perfume.name,
      'not found. ',
      perfume
    )
  return Object.values(perfume.eanData)[0]
  }
  return res
}
