<template>
  <div class="concept-box" :class="{selected}" @click="$emit('click',$event)">
    <div class="text" :class="{selected}">{{ concept.label || concept.name }}</div>
    <div v-if="selected" class="cross-icon" />
  </div>
</template>

<script>
export default {
  props: {
    concept: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
  },
}
</script>

<style lang="stylus" scoped>
.concept-box
  m-font-size(20, 26)
  display: flex
  flex: 1 1 0%
  min-width: vw(200px)
  min-width: 1px
  text-transform: capitalize
  cursor: pointer

  .mobile &
    m-font-size(17, 23)

  &:first-child
    margin-left: vw(10px)
    text-align: right

  &:last-child
    margin-right: vw(10px)

  &.selected
    font-weight: 600

  .text
    m-ellipsis(100%)
    flex: 1 1 0%
</style>